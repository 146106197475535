*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

* {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}

:root {
  tab-size: 4;
}

:-moz-focusring {
  outline: 1px dotted buttontext;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::moz-focus-inner {
  border-style: none;
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

abbr[title] {
  text-decoration: underline dotted;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

h3, h4 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

img {
  vertical-align: middle;
  border-style: solid;
  max-width: 100%;
  height: auto;
  display: block;
}

input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

input::placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

strong {
  font-weight: bolder;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.columns-2 {
  columns: 2;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-t-gray-600 {
  --tw-border-opacity: 1;
  border-top-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.border-0 {
  border-width: 0;
}

.siblings\:border-t ~ * {
  border-top-width: 1px;
}

.border-t-6 {
  border-top-width: 6px;
}

.border-x-6 {
  border-left-width: 6px;
  border-right-width: 6px;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.font-bold {
  font-weight: 700;
}

.h-full {
  height: 100%;
}

.h-6 {
  height: 1.5rem;
}

.h-0 {
  height: 0;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mr-1 {
  margin-right: .25rem;
}

.ml-1 {
  margin-left: .25rem;
}

.siblings\:mt-4 ~ * {
  margin-top: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.max-h-full {
  max-height: 100%;
}

.max-h-12 {
  max-height: 3rem;
}

.max-h-\[40vh\] {
  max-height: 40vh;
}

.outline-0 {
  outline-width: 0;
}

.overflow-auto {
  overflow: auto;
}

.p-6 {
  padding: 1.5rem;
}

.p-4 {
  padding: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.siblings\:pt-6 ~ * {
  padding-top: 1.5rem;
}

.pt-0 {
  padding-top: 0;
}

.siblings\:pt-4 ~ * {
  padding-top: 1rem;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.top-full {
  top: 100%;
}

.-left-4 {
  left: -1rem;
}

.-right-4 {
  right: -1rem;
}

.shadow-rounded-xl {
  --tw-shadow: 0 3px 30px #00000012;
  --tw-shadow-colored: 0 3px 30px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-900 {
  --tw-shadow-color: #111827;
  --tw-shadow: var(--tw-shadow-colored);
}

.text-right {
  text-align: right;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.uppercase {
  text-transform: uppercase;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-0 {
  width: 0;
}

.z-10 {
  z-index: 10;
}

html {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-gradient-from: rgba(29, 78, 216, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), rgba(96, 165, 250, var(--tw-via-opacity, 1)), var(--tw-gradient-to, #fff0);
  --tw-gradient-to: rgba(167, 243, 208, var(--tw-to-opacity, 1));
  background-image: none;
  min-height: 100%;
}

@media (width >= 640px) {
  html {
    background-image: -o-linear-gradient(top left, var(--tw-gradient-stops));
    background-image: -webkit-gradient(linear, left top, right bottom, from(var(--tw-gradient-stops)));
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  }
}

body {
  height: 100%;
  font-family: Nunito, sans-serif;
}

@media (width >= 640px) {
  body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

a {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

a:hover {
  text-decoration-line: underline;
}

@media (width >= 640px) {
  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:w-88 {
    width: 22rem;
  }
}
/*# sourceMappingURL=index.1f85ae26.css.map */
